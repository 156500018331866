<template>
  <FocusTrap :initial-focus="getInitialFocus">
    <div
      class="fixed left-0 top-0 z-page-modal flex h-screen w-screen items-center justify-center p-16"
    >
      <div class="fixed inset-0 bg-black opacity-[.35]" @click="close" />
      <div
        ref="contentEl"
        class="page-modal-content relative max-h-[calc(100dvh-32px)] w-full overflow-auto bg-white px-24 pb-40 pt-80 text-left align-middle transition-all md:p-48"
        :class="contentClass"
      >
        <button type="button" class="absolute right-32 top-32" @click="close">
          <SpriteSymbol name="close" class="h-32 w-32" />
        </button>

        <slot></slot>
      </div>
    </div>
  </FocusTrap>
</template>

<script lang="ts" setup>
import { onKeyDown } from '@vueuse/core'
import { FocusTrap } from 'focus-trap-vue'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

defineProps<{
  contentClass?: string
}>()

const contentEl = ref<HTMLDivElement | null>(null)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const close = () => emit('close')

// Close modal when pressing Escape.
onKeyDown('Escape', close)

/**
 * Determines which element should be focused initially when the modal opens.
 */
const getInitialFocus = () => {
  if (!contentEl.value) {
    return
  }

  // Try to find an input element.
  const input = contentEl.value.querySelector('input')
  if (input) {
    return input
  }

  // Fall back to the first button.
  return contentEl.value.querySelector('button')
}

onMounted(() => {
  if (!contentEl.value) {
    return
  }

  disableBodyScroll(contentEl.value, { reserveScrollBarGap: true })
})

onBeforeUnmount(() => {
  clearAllBodyScrollLocks()
})
</script>

<style lang="postcss">
.page-modal-enter-active,
.page-modal-leave-active {
  @apply transition duration-300;
  .page-modal-content {
    transition: inherit;
  }
}
.page-modal-enter-from,
.page-modal-leave-to {
  @apply opacity-0;
  .page-modal-content {
    @apply scale-95;
  }
}
</style>
